function initHeader() {
    const header = document.querySelector('header');
    if (!header) {
        return;
    }

    const nav = header.querySelector('nav');
    if (!nav) {
        return;
    }

    const toggler = document.querySelector('button.navbar-toggler');
    if (!toggler) {
        return;
    }

    // Check if the navbar has been configured to have a transparent background
    // when scrolled on top.
    if (nav.getAttribute('data-transparent-on-top') !== 'true') {
        return;
    }

    // Initialise the current navbar state.
    let currTransparent = nav.classList.contains('bg-transparent');

    const reconcile = () => {
        requestAnimationFrame(() => {
            const desiredTransparent = window.scrollY <= 0 && toggler.classList.contains('collapsed');

            if (currTransparent !== desiredTransparent) {
                if (desiredTransparent) {
                    nav.classList.add('bg-transparent');
                } else {
                    nav.classList.remove('bg-transparent');
                }

                currTransparent = desiredTransparent;
            }
        });
    };

    // Reconcile on scroll.
    window.addEventListener('scroll', reconcile);
    window.addEventListener('touchmove', reconcile);

    // Reconcile when the menu state change (on mobile).
    const togglerObserver = new MutationObserver(reconcile);
    togglerObserver.observe(toggler, {attributes: true, attributeFilter: ['class']});
}

initHeader();
